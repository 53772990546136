import OrdersIcon from 'public/img/clipboards/clipboard-text.svg'
import DashboardIcon from 'public/img/dashboard/dashboard.svg'
import Money from 'public/img/money/money.svg'
import HelpIcon from 'public/img/help.svg'
import Clipoard from 'public/img/clipboards/clipboard-tick.svg'
import Rejected from 'public/img/clipboards/clipboard-text-dark.svg'
import Team from 'public/img/users/users-grey.svg'
import Note from 'public/img/files-and-text/note-grey.svg'
import NotePlus from 'public/img/files-and-text/note-plus.svg'
import Star from 'public/img/stars/star.svg'
import Question from 'public/img/q-and-a/question-grey.svg'
import Book from 'public/img/addons/book.svg'
import Cancel from 'public/img/action-menus/minus-rounded.svg'
import Write from 'public/img/action-menus/edit-light.svg'
import Services from 'public/img/services.svg'
import Analysis from 'public/img/survey-view/questions-analysis.svg'

import {
    ADMIN,
    RESEARCHER,
    STUDIES,
    SUPERVISOR,
    USER,
    OPERATION,
    SCALE_BUILDER,
    FIELDWORK
} from '@util/roles'
import connectText from '@hooks/connectText'

const connect = connectText('common:toolbar')

const linksProps = (notification, warning) => [
    {
        role: USER,
        labelKey: connect('controlPanel'),
        href: '/dashboard',
        icon: <DashboardIcon fill="#666" className="" />,
        customIconStyle: 'transform scale-90',
        fill: true
    },
    {
        role: RESEARCHER,
        labelKey: connect('controlPanel'),
        href: '/researcher',
        icon: <DashboardIcon fill="#666" className="" />
    },
    {
        role: "customer@dem.dev",
        labelKey: connect('manageSurveys'),
        href: '/orders',
        icon: <OrdersIcon fill="#666" />,
        customIconStyle: 'transform !scale-90',
        fill: true
    },
    {
        role: "customer@dem.dev",
        labelKey: connect('addons'),
        href: '/addons',
        icon: <Services fill="#666" />,
        fill: true
    },
    {
        role: "customer@dem.dev",
        labelKey: connect('analysisPage'),
        href: '/analysis',
        icon: <Analysis fill="#666" />,
        customIconStyle: 'transform !scale-100',
        fill: true
    },
    {
        role: ADMIN,
        labelKey: connect('controlPanel'),
        href: '/admin',
        icon: <DashboardIcon fill="#666" />
    },
    {
        role: ADMIN,
        labelKey: 'common:admin.order.requestQuote',
        href: '/admin/orders/quotes-orders',
        icon: <Money fill="#666" />,
        fill: true
    },
    {
        role: ADMIN,
        labelKey: connect('admin.collectionOrders'),
        href: '/admin/orders/approved',
        icon: <Clipoard height="26" />,
        fill: true
    },
    {
        role: ADMIN,
        labelKey: connect('admin.teamManagement'),
        href: '/admin/team-members',
        icon: <Team />
    },
    {
        role: ADMIN,
        labelKey: connect('admin.templates'),
        href: '/admin/templates',
        icon: <Note />
    },
    {
        role: SCALE_BUILDER,
        labelKey: connect('scaleBuilder'),
        href: '/scale-builder',
        icon: <Note fill="#666" />
    },
    {
        role: ADMIN,
        labelKey: connect('admin.questionBank'),
        href: '/admin/question-bank',
        icon: <Question />
    },
    {
        role: SUPERVISOR,
        callbackFunction: 'none',
        labelKey: connect('controlPanel'),
        href: '/supervisor',
        icon: <DashboardIcon fill="#666" className="" />
    },
    {
        role: SUPERVISOR,
        callbackFunction: 'none',
        labelKey: 'common:supervisor.toolbar.assignedOrders',
        href: '/supervisor/assigned-orders',
        icon: <OrdersIcon fill="#666" />,
        customIconStyle: 'transform !scale-90',
        fill: true
    },
    {
        role: SUPERVISOR,
        labelKey: 'common:researcher.finishedOrders',
        href: '/supervisor/finished-orders',
        icon: <Clipoard />,
        customIconStyle: 'transform !scale-90',
        fill: true
    },
    {
        role: SUPERVISOR,
        callbackFunction: 'none',
        labelKey: 'common:supervisor.toolbar.rejectedMyAssignments',
        href: '/supervisor/rejected-assignments',
        icon: <Cancel />,
        isNotification: warning
    },
    {
        role: SUPERVISOR,
        callbackFunction: 'none',
        labelKey: 'common:supervisor.toolbar.researchersAndTrainees',
        href: '/supervisor/team-management/team/all',
        icon: <Team />
    },
    {
        role: RESEARCHER,
        labelKey: connect('admin.orders'),
        href: '/researcher/orders',
        icon: <OrdersIcon fill="#666" height="30" />,
        fill: true
    },
    {
        role: RESEARCHER,
        labelKey: 'common:researcher.finishedOrders',
        href: '/researcher/finished-orders',
        icon: <Clipoard height="30" />,
        fill: true
    },
    {
        role: RESEARCHER,
        labelKey: 'common:researcher.rejectedOrders',
        href: '/researcher/rejected-orders',
        icon: <Rejected height="30" />,
        fill: true
    },
    {
        role: ADMIN,
        labelKey: connect('help'),
        href: '/admin/help',
        icon: <HelpIcon fill="#666" />
    },
    {
        role: STUDIES,
        labelKey: connect('controlPanel'),
        href: '/studies',
        icon: <DashboardIcon fill="#666" className="" />
    },
    {
        role: STUDIES,
        labelKey: connect('studies.create-for-me'),
        href: '/studies/create-for-me',
        icon: <NotePlus stroke="#666" />
    },
    {
        role: STUDIES,
        labelKey: connect('studies.review'),
        href: '/studies/review',
        icon: <Star stroke="#666" />
    },
    {
        role: STUDIES,
        labelKey: connect('studies.report'),
        href: '/studies/report',
        icon: <Book stroke="#666" />
    },
    {
        role: STUDIES,
        labelKey: connect('studies.write'),
        href: '/studies/write',
        icon: <Write stroke="#666" />,
        fill: false
    },
    {
        role: OPERATION,
        labelKey: connect('controlPanel'),
        href: '/operation',
        icon: <DashboardIcon fill="#666" className="" />
    },
    {
        role: OPERATION,
        labelKey: connect('operations.orders'),
        href: '/operation/orders',
        icon: <OrdersIcon fill="#666" />,
        customIconStyle: 'transform !scale-90',
        fill: true
    },
    {
        role: FIELDWORK,
        labelKey: connect('controlPanel'),
        href: '/fieldwork',
        icon: <DashboardIcon fill="#666" className="" />,
        customIconStyle: 'transform scale-90',
        fill: true
    },
    {
        role: FIELDWORK,
        labelKey: 'common:admin.orders',
        href: '/fieldwork/orders',
        icon: <OrdersIcon fill="#666" />,
        customIconStyle: 'transform !scale-90',
        fill: true
    },
    {
        role: FIELDWORK,
        labelKey: connect('admin.teamManagement'),
        href: '/fieldwork/team-members',
        icon: <Team />
    },
    {
        role: [USER, RESEARCHER, SUPERVISOR, STUDIES, OPERATION],
        labelKey: connect('help'),
        href: '/help',
        icon: <HelpIcon fill="#666" />,
        fill: true
    }
]

export default linksProps
