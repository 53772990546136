
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import Head from 'next/head'
import React, { useEffect, useCallback } from 'react'
import '../styles/globals.css'
import 'survey-creator-core/survey-creator-core.min.css'
import 'survey-core/defaultV2.min.css'
import Layout from '@components/layout'
import Authentication from '@providers/authentication'
import App from 'next/app'
import '@components/survey/SurveyGraphs.css'
import { useRouter } from 'next/router'
import AuthorizationEnforcer from '@components/AuthorizationEnforcer'
import LayoutHome from '@components/home/layout-home'
import noval from 'noval'
import createDispatch from '@util/noval-helper/create-dispatcher'
import createState from '@util/noval-helper/create-state'
import LayoutNotifications from '@components/notifications/LayoutNotifications'

const ProviderNoval = noval(createState, createDispatch)

if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
    const { worker } = require('mocks/browser')
    worker.start({ onUnhandledRequest: 'bypass' })
}

function MyApp({ Component, pageProps }) {
    const { locale, asPath } = useRouter()

    useEffect(() => {
        document.documentElement.dir = locale === 'ar' ? 'rtl' : 'ltr'
    }, [locale])

    const isHome = isHomePage(asPath)
    const addLinear = /^(\/(ar|en))?\/contact_us/.test(asPath)

    const RenderLayout = useCallback(
        ({ children }) => {
            return isHome ? (
                <>
                    <LayoutHome linear={addLinear}>{children}</LayoutHome>
                    <LayoutNotifications />
                </>
            ) : (
                <>
                    <Layout>{children}</Layout>
                    <LayoutNotifications />
                </>
            )
        },
        [isHome, addLinear]
    )

    return (
        <>
            <Head>
                <meta charSet="utf-8" />
                <meta
                    httpEquiv="Content-Type"
                    content="text/html;charset=utf-8"
                />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta
                    name="viewport"
                    content="initial-scale = 1.0,maximum-scale = 1.0"
                />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge,Chrome=1" />
                <meta name="author" content="upsilon" />
                <meta name="robots" content="index, follow" />
                <meta content="article" property="og:type" data-rh="true" />
                <meta property="og:site_name" content="upsilon" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com"
                    crossOrigin
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Noto+Naskh+Arabic:wght@400..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
                    rel="stylesheet"
                />
            </Head>
            <ProviderNoval>
                <Authentication>
                    <AuthorizationEnforcer>
                        <RenderLayout>
                            <Component {...pageProps} />
                        </RenderLayout>
                    </AuthorizationEnforcer>
                </Authentication>
            </ProviderNoval>
        </>
    )
}

const isHomePage = (url) => {
    return [
        /^\/(\?.*)?$/,
        /^(\/(ar|en))?\/about/,
        /^(\/(ar|en))?\/services/,
        /^(\/(ar|en))?\/contact_us/
    ].some((regex) => regex.test(url))
}

MyApp.getInitialProps = async (appContext) => {
    return App.getInitialProps(appContext)
}

const __Page_Next_Translate__ = MyApp


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  