import React from 'react'
import setLanguage from 'next-translate/setLanguage'
import LangIcon from 'public/img/lang.svg'
import useTranslation from 'next-translate/useTranslation'
import Button from '@components/button'
import Link from 'next/link'

const NavbarButtons = ({ localSwitchTarget, mainClass }) => {
    const { t } = useTranslation()

    const buttonsData = [
        {
            buttonType: { borderBrown: true },
            customStyle: '!text-white border-none !p-0',
            buttonContent: (
                <div
                    className="flex w-full flex-row gap-1 justify-center items-center"
                    onClick={() => setLanguage(localSwitchTarget)}
                >
                    <LangIcon fill="#fff" />
                    <h3 className="capitalize">
                        {t(`common:header.language.${localSwitchTarget}`)}
                    </h3>
                </div>
            )
        }
    ]

    return buttonsData.map(
        ({ buttonType, customStyle, buttonContent, link, label }) => (
            <Button
                {...buttonType}
                customClassName={`${customStyle} ${mainClass}`}
            >
                {buttonContent ?? (
                    <Link href={link}>
                        <a className="flex justify-center items-center text-md">
                            {label}
                        </a>
                    </Link>
                )}
            </Button>
        )
    )
}

export default NavbarButtons
