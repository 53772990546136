import gql from 'graphql-tag'
import { useMemo } from 'react'
import getTypes from 'gql/types'
import useQuery from './useQuery'

function isMark(param, variables) {
    const mark = variables?.[param]?.mark
    if (mark) variables[param] = variables?.[param]?.data
    return mark || ''
}

function getHeader(vars) {
    let renderTypes = ''
    const keys = Object.keys(vars)

    keys?.forEach((param) => {
        const type = getTypes[param] + isMark(param, vars)
        renderTypes += `$${param}: ${type}\n`
    })

    return {
        keys,
        renderTypes,
        variables: vars
    }
}

export const gqlWithoutParams = ({ name, body }) => ({
    query: gql`
        query ${name} {
            ${name} {
                ${body}
            }
        }
    `,
    variables: undefined
})

export const gqlWithoutParamsAndBody = ({ name }) => ({
    query: gql`
        mutation ${name} {
            ${name}
        }
    `
})

export const insertGql = (name, body, vars) => {
    let renderVars = ''
    vars?.forEach((param) => {
        renderVars += `${param}: $${param}\n`
    })

    const head = `${name}(${renderVars})`
    const buildBody = `${head} {
        ${body}
    }`
    return body ? buildBody : head
}

export const gqlWithParams = (
    { name, body, custom, vars = {} },
    type = 'query'
) => {
    const { keys, variables, renderTypes } = getHeader(vars)

    return {
        variables,
        query: keys?.length
            ? gql`
            ${type} ${name}(${renderTypes}) {
                ${custom || insertGql(name, body, keys)}
            }`
            : false
    }
}

export const Mark = (data) => ({ data, mark: '!' })

/**
 * @param {Object} params
 * @param {String} name
 * @param {String} params.body
 * @param {Object?} params.vars
 * @param {Object?} params.custom
 * @param {Object?} params.opts
 * @param {Array<String>?} cache
 * @returns {Object}
 * @example
 * const { data } = useGql('ordersHasAddons',
 * {
 *    body: `
 *       id
 *       status
 *       createdDate
 *     `,
 *     vars: {
 *        orderStatus: ['DRAFT']
 *     },
 *     opts: {
 *       enabled: !!orderStatus
 *     }
 * }, ['cache'])
 */

const useGql = (name, { vars, body, custom, enabled, opts }, cache = []) => {
    const { query, variables } = useMemo(() => {
        const buildGql = vars
            ? gqlWithParams({ name, vars, body, custom })
            : gqlWithoutParams({ name, body })

        return buildGql
    }, [vars])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return query ? useQuery(cache, query, variables, opts, enabled) : {}
}

export default useGql
