export const USER = 'USER'
export const ADMIN = 'ADMIN'
export const SUPERVISOR = 'BACKOFFICE_SUPERVISOR'
export const RESEARCHER = 'BACKOFFICE_RESEARCHER'
export const FIELDWORK = 'BACKOFFICE_FIELDWORK'
export const STUDIES = 'STUDIES_UNIT'
export const OPERATION = 'OPERATION_UNIT'
export const SCALE_BUILDER = 'SCALE_BUILDER'

export const hasRole = (role, userRoles) => {
    if (Array.isArray(role)) {
        return role.some((role) => userRoles?.includes(role))
    } else {
        return userRoles?.includes(role)
    }
}

export const hasAnyRole = ([role], userRoles) => {
    if (Array.isArray(role)) {
        return role.some((role) => userRoles?.includes(role))
    } else {
        return userRoles?.includes(role)
    }
}
