/**
 * Add the constant text then use connect for a variable text
 *
 * ex :
 *
 * const connect  = connectText('common:test')
 *
 * connect("var") => common:test.var
 *
 * @param {constant , dot} constant
 * @returns {Function}
 */

const connectText =
    (constant, dot = '.') =>
    (text) =>
        `${constant}${dot ?? ''}${text}`

export default connectText
